













import { Component, Vue } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import PrivacyPolicyText from '@/app/components/PrivacyPolicyText.vue';

@Component({
  components: {
    PrivacyPolicyText,
    Card,
    GridCol,
    GridRow,
    MainHeader,
  },
})
export default class PrivacyPolicyView extends Vue {
}
