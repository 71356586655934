












































































































































































import { Component, Vue } from 'vue-property-decorator';
import StaticContent from '@/shared/resources/components/StaticContent.vue';

@Component({
  components: {
    StaticContent,
  },
})
export default class PrivacyPolicyText extends Vue {

}
